import type { GatsbyBrowser } from "gatsby";
import * as React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import useFizjoterapia from "./src/hooks/useFizjoterapia";
import { GlobalStyles } from "./src/styles/GlobalStyles";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props: { location },
}) => {
  const isFizjoterapia = useFizjoterapia(location);
  return (
    <>
      <GlobalStyles isFizjoterapia={isFizjoterapia} />
      <h1 style={{ display: "none" }} aria-hidden="true">
        Fizjoterapia - Katarzyna Krej - Profesjonalna Fizjoterapia i Masaż w
        Twojej Okolicy (Studzionka, Pszczyna, Jastrzębie, Pawłowice, Żory) -
        FIZJOKREJ
      </h1>
      <ParallaxProvider>{element}</ParallaxProvider>
    </>
  );
};

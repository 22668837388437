import { createGlobalStyle } from "styled-components";

interface GlobalStylesProps {
  isFizjoterapia?: boolean;
}

export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  :root {
    --font-family-main: 'Comfortaa', sans-serif;
    --color-white: #FFF;
    --color-black: #000;
    --color-gray: #1E1E1E;
    ${(props) => {
      return props.isFizjoterapia
        ? `
    --color-green-dark: #074c36;
    --color-green: #C1E6BA;
    --color-green-light: #EAF8E7;
    `
        : `
    /* Wersja dla masażu - kolory beżowe */
    --color-green-dark: #a07250; 
    --color-green: #d1af94;
    --color-green-light: #EFE6E0;
    `;
    }}
    /* font-size */
    --font-size-info-image: 5.1rem; /* 64px */
    --font-size-title: 3.4rem; /* 64px */
    --font-size-description: 1.25rem; /* 20px */
    --font-size-breadcrumb: 0.8125rem; /* 13px */
    --font-size-form: 1.125rem; /* 18px */
    --font-size-normal: 1rem; /* 16px */
    --font-size-review: 1.25rem; /* 20px */
    --font-size-main: 1.4rem; /* 24px */
    --font-size-footer: 2rem; /* 32px */
    --font-size-large: 2.5rem; /* 40px */
    --font-size-xlarge: 3rem; /* 48px */
    /* other */
    --border-radius: 1.25rem; /* 20px */
    --menu-height: 8rem; /* 128px */
    --main-image-bottom-space: 3rem; /* 48px */
    /* space */
    --space-xs: 0.625rem; /* 10px */
    --space-sm: 1.25rem; /* 20px */
    --space-md: 1.875rem; /* 30px */
    --space-lg: 2.5rem; /* 40px */
    --space-xl: 3.125rem; /* 50px */
    --space-xl-mid: 4.5rem; /* 72px */
    --space-xxl: 4rem; /* 64px */
    font-size: 100%; /* 16px */
  }

  body {
    font-family: var(--font-family-main);
    margin: 0;
    padding: 0;
    color: var(--color-black);
    line-height: var(--line-height-normal);
    font-size: var(--font-size-main); /* 24px */
  }

  button {
    font-family: 'Comfortaa', sans-serif;
  }

  a {
    font-size: var(--font-size-main); /* 24px */
    color: var(--color-black);
    :hover {
      text-decoration: none;
    }
  }

  h3 {
    font-size: var(--font-size-large); /* 40px */
    font-weight: bold;
    margin: 0;
    line-height: 170%;
  }

  p {
    font-size: var(--font-size-main); /* 24px */
    line-height: 150%; /* 40px */
    margin: 0;
  }

  li p {
    line-height: 170%;
  }

  .custom-marker-label {
    transform: translateY(-1.5625rem); /* -25px */
  }

  @media (max-width: 320px) {
    :root {
      font-size: 80%; /* 14.4px */
      --menu-height: 4rem;
      --font-size-info-image: 1.5rem; /* 24px */
      --font-size-title: 1.5rem; /* 24px */
      --font-size-description: 1.125rem; /* 18px */
      --font-size-breadcrumb: 0.73125rem; /* 11.7px */
      --font-size-form: 1.0125rem; /* 16.2px */
      --font-size-normal: 0.9rem; /* 14.4px */
      --font-size-review: 1.125rem; /* 18px */
      --font-size-main: 1.35rem; /* 21.6px */
      --font-size-footer: 1.8rem; /* 28.8px */
      --font-size-large: 2.25rem; /* 36px */
      --font-size-xlarge: 2.7rem; /* 43.2px */
      --space-xl-mid: 3.6rem;
      --space-xxl: 3.2rem;
    }
  }

  @media (max-width: 640px) and (min-width: 321px) {
    :root {
      font-size: 84%; /* 14.4px */
      --menu-height: 5rem;
      --font-size-info-image: 2.8rem; /* 24px */
      --font-size-title: 1.5rem; /* 24px */
      --font-size-description: 1.125rem; /* 18px */
      --font-size-breadcrumb: 0.73125rem; /* 11.7px */
      --font-size-form: 1.0125rem; /* 16.2px */
      --font-size-normal: 0.9rem; /* 14.4px */
      --font-size-review: 1.125rem; /* 18px */
      --font-size-main: 1.35rem; /* 21.6px */
      --font-size-footer: 1.8rem; /* 28.8px */
      --font-size-large: 2.25rem; /* 36px */
      --font-size-xlarge: 2.7rem; /* 43.2px */
      --space-xl-mid: 3.6rem;
      --space-xxl: 3.2rem;
    }
  }

  @media (min-width: 641px) and (max-width: 768px) {
    :root {
      font-size: 95%; /* 15.2px */
      --font-size-normal: 1.06875rem; /* 17.1px */
      --font-size-main: 1.425rem; /* 22.8px */
      --font-size-title: 2.5rem; /* 40px */
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    :root {
      font-size: 100%; /* 16px */
    }
  }
  
  @media (min-width: 1537px) {
    :root {
      font-size: 112.5%; /* 18px */
    }
  }
`;

// Ustawienie defaultProps na true
GlobalStyles.defaultProps = {
  isFizjoterapia: true,
};

import { useMemo } from "react";

interface LocationProps {
  pathname: string;
}

const useFizjoterapia = (locationFromProps?: LocationProps): boolean =>
  useMemo(() => {
    const locationObject =
      locationFromProps ||
      (typeof window !== "undefined" ? window.location : { pathname: "" });
    const pathname = locationObject.pathname;
    return pathname.includes("/fizjoterapia") || !pathname.includes("/masaz");
  }, [locationFromProps]);

export default useFizjoterapia;

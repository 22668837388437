exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-przygotuj-sie-tsx": () => import("./../../../src/pages/przygotuj-sie.tsx" /* webpackChunkName: "component---src-pages-przygotuj-sie-tsx" */),
  "component---src-pages-terapie-tsx": () => import("./../../../src/pages/terapie.tsx" /* webpackChunkName: "component---src-pages-terapie-tsx" */),
  "component---src-templates-physiotherapy-method-template-tsx": () => import("./../../../src/templates/PhysiotherapyMethodTemplate.tsx" /* webpackChunkName: "component---src-templates-physiotherapy-method-template-tsx" */)
}

